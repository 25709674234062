<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="entityModel" :items="entityLists" default="" item-value="entity_id" item-text="entity_id" label="Entity" :disabled="true" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Ship From" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" dense label="Start Date" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" dense label="End Date" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-4">
                                <v-btn class="mt-1" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Result</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.showDetails`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip @click="showItemDetails(item)" color="blue" link outlined v-on="on">Details</v-chip>
                                        </template>
                                        <span>Show Detail</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:footer>
                                    <span class="text-primary"> Delivery Note Total : {{ sumDelivNote ? sumDelivNote : '-'}}</span> <br />
                                    <span class="text-primary"> Total Goods : IDR {{ sumTotalGoods ? sumTotalGoods : '-'}}</span><br />
                                    <span class="text-primary"> Total Shipping Cost : IDR {{ sumShippingCost ? sumShippingCost : '-'}}</span><br />
                                    <span class="text-primary"> Total Cost Ratio : {{ sumCostRatio ? sumCostRatio : '-'}} %</span>
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div>


        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                    </v-data-table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KMB',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Cost Ratio',
                disabled: true,
                href: '#',
                },
            ],
            entityModel: 'KMB',
            entityLists: [
                { entity_id: 'KMB' },
            ],
            millModel: '',
            millLists: [],
            dateStartModel: '',
            dateStartModal: false,
            dateEndModel: '',
            dateEndModal: false,
            itemLists: [],
            headers: [
                { text: 'SHIP. DATE', value: 'tglKirim', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. NOTE', value: 'JumlahSJ', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'GOODS(IDR)', value: 'IDRBarang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIPPING COST(IDR)', value: 'IDROngkir', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COST RATIO(%)', value: 'persen', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            searchItem: '',
            loadingDatatable: false,
            sumDelivNote: '',
            sumTotalGoods: '',
            sumShippingCost: '',
            sumCostRatio: '',
            itemDetailLists: [],
            headersDetails: [
                { text: 'DESTINATION', value: 'destination', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'exp_descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DRIVER', value: 'driver_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'VEHICLE NO.', value: 'vehicle_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. NOTE', value: 'JumlahSJ', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'GOODS(IDR)', value: 'IDRBarang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. COST(IDR)', value: 'IDROngkir', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RATIO(%)', value: 'persen', align: 'right', class: 'primary--text blue lighten-5' }
            ],
            loadingDatatableDetail: false,

        }
    },
    async mounted(){
        
        this.initialize()
        document.querySelector(".cardResult").style.display = "none"

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CostRatio`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.$store.dispatch('setOverlay', false)
            })
        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Ship From',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CostRatio/getData`, { 

                    entity: this.entityModel ? this.entityModel : '',
                    mill: this.millModel ? this.millModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    this.sumDelivNote = res.data.summary[0].totalSJ
                    this.sumTotalGoods = res.data.summary[0].totalIDRbarang
                    this.sumShippingCost = res.data.summary[0].totalIDROngkir
                    this.sumCostRatio = res.data.summary[0].costRatio
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            })

        },

        async showItemDetails(id){
            $('#detailModal').modal('show')
            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CostRatio/getDataDetails?entity=${id.entity}&mill=${id.mill_id}&date=${id.tglKirim}`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
            })
        },

        async populateMills(id){

            if(id){

                this.$store.dispatch('setOverlay', true)

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CostRatio/populateMills`, { 
                        entity: id
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    }
                )
                .then(res => {

                    this.$store.dispatch('setOverlay', false)

                    this.millLists = res.data.result

                })

            } else {
                this.millLists = []
            }

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>